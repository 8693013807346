import Header from "./Header";

export default function App() {
  return (
    <>
      <Header />
      <main className="flex flex-col flex-auto bg-bgcolor-light text-black dark:bg-bgcolor-dark dark:text-white">
        <div
          style={{ backgroundImage: "url('/images/background.jpg')" }}
          className="h-screen bg-center bg-cover"
        >
          <div className="absolute bottom-1/2 md:right-1/2 md:translate-x-1/2 translate-y-1/2 w-[960px] max-w-full p-4 pt-20 overflow-hidden">
            <img
              className="m-auto pb-4 md:pb-6"
              src="/images/zanecraft.png"
              alt="Zanecraft"
            />
            <p className="m-auto pb-2 font-display font-bold text-3xl text-white text-center uppercase drop-shadow-[0_0_5px_rgba(0,0,0,0.5)] sm:text-4xl md:pb-4 md:text-5xl">
              Sei bereit für Staffel&nbsp;2!
            </p>
            <p className="m-auto pb-8 drop-shadow-[0_0_5px_rgba(0,0,0,0.5)] text-2xl text-white text-center sm:text-3xl md:pb-12 md:text-4xl">
              Nur auf{" "}
              <a className="underline" href="https://discord.gg/zane">
                discord.gg/zane
              </a>
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
