import CloudOffIcon from "@mui/icons-material/CloudOff";
import PeopleIcon from "@mui/icons-material/People";
import { SvgIcon } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const serverHost = "zanecraft.net";
const serverPort = 25565;

export default function Header() {
  const [status, setStatus] = React.useState(null);

  React.useEffect(() => {
    fetch(`https://mcapi.us/server/status?ip=${serverHost}&port=${serverPort}`)
      .then((response) => response.json())
      .then((data) => {
        setStatus(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <header className="fixed top-0 z-50 w-full h-16 bg-bgcolor-light shadow-md dark:bg-dbgcolor-dark">
      <div className="flex flex-row justify-between gap-8 h-full max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="flex flex-row items-center gap-8 select-none">
          <Link
            className="font-display text-2xl font-semibold text-accent-light dark:text-accent-dark sm:text-3xl"
            to="/"
          >
            zanecraft.net
          </Link>
        </div>
        <div className="flex flex-row items-center gap-8 font-display text-xl">
          {status == null ? (
            <></>
          ) : !status["online"] || !("now" in status["players"]) ? (
            <>
              <span className="hidden sm:block">Server Offline</span>
              <SvgIcon className="block sm:!hidden" component={CloudOffIcon} />
            </>
          ) : (
            <>
              <div className="flex flex-row items-center gap-2">
                <span className="font-mono">{status["players"]["now"]}</span>
                <span className="hidden sm:inline">Spieler Online</span>
                <div className="fill-black dark:fill-white sm:hidden">
                  <SvgIcon component={PeopleIcon} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
